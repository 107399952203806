import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route /*useLocation*/,
} from "react-router-dom";
import Footer from "./components/Footer";
import { MasterplanLayerProvider } from "./context/MasterplanLayerContext";
import { VisualisationLayerProvider } from "./context/VisualisationLayerContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import { MobileDesign } from "./context/MobileContext";
import esriId from "@arcgis/core/identity/IdentityManager";
import PrivateRoute from "./context/ProtectedRoute";
import Fallback from "./components/Fallback";
import { FOOTER_HEIGHT, HEADER_HEIGHT } from "./constants";

const Home = React.lazy(() => import("./pages/Home"));
const Masterplan = React.lazy(() => import("./pages/Masterplan"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const RegisterPage = React.lazy(() => import("./pages/RegisterPage"));
const Visualisation = React.lazy(() => import("./pages/Visualisation"));
const Renders = React.lazy(() => import("./pages/Visualisation/Renders"));
const Guidelines = React.lazy(() => import("./pages/Guidelines"));
const NoPage = React.lazy(() => import("./pages/NoPage"));
const Infrastructure = React.lazy(() => import("./pages/Infrastructure"));
const Landscape = React.lazy(() => import("./pages/Landscape"));
const Experience = React.lazy(() => import("./pages/Experience"));
const Profile = React.lazy(() => import("./pages/Profile"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const DataRetention = React.lazy(() => import("./pages/DataRetention"));

const App: React.FC = () => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      esriId.destroyCredentials();
    };

    // Listen for session end
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <MobileDesign>
      <MasterplanLayerProvider>
        <VisualisationLayerProvider>
          <Router>
            <Suspense fallback={<Fallback />}>
              <Header />
              <div
                style={{
                  backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url(https://d1kodva67b91g8.cloudfront.net/Other/background.jpg)`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
                }}
              >
                <Routes>
                  <Route path="/*" element={<NoPage />} />
                  <Route path="/" element={<LoginPage />} />
                  <Route path="/register" element={<RegisterPage />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/data-retention" element={<DataRetention />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/home" element={<PrivateRoute />}>
                    <Route path="/home" element={<Home />} />
                  </Route>
                  <Route path="/masterplan" element={<PrivateRoute />}>
                    <Route path="/masterplan" element={<Masterplan />} />
                  </Route>
                  <Route path="/visualisation" element={<PrivateRoute />}>
                    <Route path="/visualisation" element={<Visualisation />} />
                  </Route>
                  <Route path="/renders" element={<PrivateRoute />}>
                    <Route path="/renders" element={<Renders />} />
                  </Route>
                  <Route
                    path="/experience"
                    element={<PrivateRoute privateOnly={true} />}
                  >
                    <Route path="/experience" element={<Experience />} />
                  </Route>
                  <Route
                    path="/guidelines"
                    element={<PrivateRoute privateOnly={true} />}
                  >
                    <Route path="/guidelines" element={<Guidelines />} />
                  </Route>
                  <Route
                    path="/infrastructure"
                    element={<PrivateRoute privateOnly={true} />}
                  >
                    <Route
                      path="/infrastructure"
                      element={<Infrastructure />}
                    />
                  </Route>
                  <Route path="/landscape" element={<PrivateRoute />}>
                    <Route path="/landscape" element={<Landscape />} />
                  </Route>
                  <Route path="/profile" element={<PrivateRoute />}>
                    <Route path="/profile" element={<Profile />} />
                  </Route>
                </Routes>
              </div>
              <Footer />
            </Suspense>
          </Router>

          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </VisualisationLayerProvider>
      </MasterplanLayerProvider>
    </MobileDesign>
  );
};

export default App;
