// Components
export const HEADER_HEIGHT = 80;
export const FOOTER_HEIGHT = 80;
export const DESKTOP_4K_BREAKPOINT = 2560;
export const DESKTOP_EXTRALARGE_BREAKPOINT = 1640;
export const DESKTOP_LARGE_BREAKPOINT = 1440;
export const DESKTOP_MEDIUM_BREAKPOINT = 1280;
export const DESKTOP_BREAKPOINT = 1024;
export const TABLET_BREAKPOINT = 768;
export const MOBILE_LARGE_BREAKPOINT = 425;
export const MOBILE_MEDIUM_BREAKPOINT = 375;
export const MOBILE_SMALL_BREAKPOINT = 320;

export const ESRI_BANNER_HEIGHT = 16;

//Firebase:
export const FIREBASE_PREFIX =
  "https://us-central1-tncdt-930db.cloudfunctions.net";

// URL
export const PRE_CMP_FEATURE_LAYER_URL =
  "https://services-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/240123_TNC_01_PreCMP_gdb/FeatureServer";
export const D1_CMP_FEATURE_LAYER_URL =
  "https://services-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/240124_TNC_02_D1_CMP_gdb/FeatureServer";
export const D1_DMP_FEATURE_LAYER_URL =
  "https://services-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/20240603_XW_NT_D1_STAGE_3___v5_0_MASTERPLAN/FeatureServer";
export const D1_DMP_INFRASTRUCTURE_LAYER_URL =
  "https://services-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/20240603_XW_NT_D1_STAGE_2___v3_9_INFRASTRUCTURE/FeatureServer";
export const D1_DMP_6100HA_URL =
  "https://services-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/_XW_NT_D1_DMP_GIS_MASTERPLAN_6100ha_STAGE_3/FeatureServer";
export const SCENE_LAYER_URL =
  "https://tiles-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/D1_DMP_3D_Massing_V3/SceneServer";
export const RENDER_LOCATION_LAYER_URL =
  "https://services-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/240510_Viz_Locations/FeatureServer";
export const PRE_CMP_TILE_LAYER =
  "https://tiles-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/WEBMERCATOR2/MapServer";
export const D1_DMP_TILE_LAYER_ZOOM_IN =
  "https://tiles-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/NT_BASEMAP_ZOOM_IN_WEBMERCATOR/MapServer";
export const D1_DMP_TILE_LAYER_ZOOM_OUT =
  "https://tiles-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/NT_BASEMAP_ZOOM_OUT_WEBMERCATOR/MapServer";
export const D1_DMP_ELEVATION_LAYER =
  "https://tiles-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/Stretch_TOPO_WEBMERCATOR_SINGLE_BAND2/ImageServer";

// Layer Name
export const LAYER_BLOCK_PLAN = "block_plan";

export const LAYER_VIEW_D1DMP = "d1_dmp_views";
export const LAYER_BLOCK_PLAN_D1DMP_Blocks = "d1_dmp_blocks";
export const LAYER_VIEW_D1CMP = "d1_cmp_views";
export const LAYER_BLOCK_PLAN_D1CMP_Blocks = "d1_cmp_blocks";
export const LAYER_VIEW_PRECMP = "pre_cmp_views";
export const LAYER_BLOCK_PLAN_PRECMP_Blocks = "pre_cmp_blocks";

// Color Schemes
export const COLOUR_SCHEME_1 = [
  "#e6194B", // Red
  "#3cb44b", // Green
  "#ffe119", // Yellow
  "#4363d8", // Blue
  "#f58231", // Orange
  "#911eb4", // Purple
  "#46f0f0", // Cyan
  "#f032e6", // Magenta
  "#bcf60c", // Lime
  "#fabebe", // Pink
];

export const COLOUR_SCHEME_2 = [
  "#008080", // Teal
  "#e6beff", // Lavender
  "#9A6324", // Brown
  "#fffac8", // Beige
  "#800000", // Maroon
  "#aaffc3", // Mint
  "#808000", // Olive
  "#ffd8b1", // Apricot
  "#000075", // Navy
  "#808080", // Grey
];

export const COLOUR_SCHEME_3 = [
  "#84B683", // Green - T1
  "#B4DA8E", // Lime - T2
  "#F5F1C5", // Light grayish yellow - T3
  "#EFDF81", // Soft yellow - T4
  "#EFC298", // very soft orange -T5
  "#EF958C", // very soft red - T6
  "#D0676C", // moderate red - T7
  "#B6D8E2", // Light grayish cyan -TS
  "#ffe119", // Yellow
  "#4363d8", // Blue
  "#f58231", // Orange
  "#008080", // Teal
  "#e6beff", // Lavender
  "#9A6324", // Brown
  "#fffac8", // Beige
  "#800000", // Maroon
  "#aaffc3", // Mint
  "#808000", // Olive
  "#ffd8b1", // Apricot
  "#000075", // Navy
  "#808080", // Grey
];

// Map
export const DEFAULT_STAGE = "d1-dmp";

/**
 * A mapping of ArcGIS attribute keys to more user-friendly names.
 */
export const ATTRIBUTE_KEY_MAPPING: { [key: string]: string } = {
  UNIQUEID: "Unique ID by Database",
  OBJECTID: "Unique ID by Feature Layer",
  PRJ_NAME: "Project Name",
  MP_VER: "Master Plan Version",
  MP_REV: "Master Plan Revision",
  MP_STA: "Master Plan Version Stage",
  DES_STATUS: "Design Status",
  CRE_DATE: "Creation date",
  APP_DATE: "Approval Date",
  MP_LEAD: "Design Lead",
  MP_SUB: "Sub Consultant",
  CRE_BY: "Created By",
  MP_REP_LI: "Master Plan Reports Link",
  PHA_ID: "Phase ID",
  PHA_RAN: "Phase Period of time",
  LAND_AREA: "Land Area",
  GFA: "Assigned GFA",
  ASG_BUA: "Assigned BUA",
  ASG_FAR: "Assigned FAR",
  POP: "Forecasted Population",
  DIS_ID: "District ID",
  DIS_NAME: "District Name",
  ZN_ID: "Masterplan Zone ID",
  UN_ID: "Urban Neighbourhood ID",
  EX_SET_NAME: "Existing Settlement Name",
  SBL_ID: "SuperBlock ID",
  BLO_ID: "Block ID",
  TC_CODE: "TC_Block Code",
  CF: "Client Designated Community Facilities",
  TC_TRANSECT: "TC_Block Transect",
  TC_LAN_USE_1: "TC_Primary Land Use",
  TC_LAN_USE_MIX: "TC_Land Use Mix",
  TC_LAN_USE_2: "TC_Secondary Land Use",
  TC_BLO_COMP: "TC_Block Composition",
  TC_ASG_FLOORS: "TC_Max Number of Floors Assigned",
  TC_FAR_AVG: "TC_Average Floor Area Ratio",
  TC_BCR_AVG: "TC_Average Block Coverage",
  TC_GFA_MAX: "TC_Max Gross Floor Area",
  TC_FAR_MIN: "TC_Min Floor Area Ratio",
  TC_FAR_MAX: "TC_Max Floor Area Ratio",
  TC_BCR_MIN: "TC_Min Block Coverage",
  TC_BCR_MAX: "TC_Max Block Coverage",
  TC_POP: "TC_Forecasted Population",
  GEO_TY: "Illustrative Geometry Type",
  RD_TY: "Road Type Name",
  RD_STY: "Road Sub-Type",
  RD_ROW: "Row",
  RD_IMG: "Road Section Link JPEG",
  RD_CAD: "Road Section Link CAD",
  PT_LIN_TY: "PT Line Type",
  PT_LIN_NAME: "PT Line Name",
  PT_STA_TY: "PT Station Type",
  PT_STA_NAME: "PT Station Name",
  PT_STA_COV: "PT Station Coverage Radius",
  LS_LAN_USE_1: "Landscape Primary Land Use",
  LS_LAN_USE_2: "Landscape Secondary Land Use",
  LS_LAN_USE_DETAIL: "Landscape Detailed Land Use",
  UT_CAN_NAME: "Canal Name",
  UT_CAN_GEL: "Canal Ground Level Elevation",
  UT_CAN_PEL: "Canal Project Level Elevation",
  UT_PIP_TY: "Pipe Type",
  UT_PIP_ELEV_START: "Pipe Start Elevation",
  UT_PIP_ELEV_END: "Pipe End Elevation",
  UT_PIP_ELEV: "Pipe Spot Elevation",
  UT_PIP_DIA: "Pipe Segment Diameter",
  UT_PIP_SLO: "Pipe Segment Slope",
  UT_PIP_LEN: "Pipe Segment Length",
  UT_MH_TY: "Manhole Type",
  UT_MH_ID: "Manhole ID",
  UT_MH_ELEV: "Manhole Spot Elevation",
  UT_BAS_TY: "Basin Type",
  UT_BAS_ID: "Basin ID",
  UT_BAS_AREA: "Basin Area",
  UT_BAS_C: "Basin C Factor",
  UT_OPT_TY: "Culvert Type",
  UT_OPT_ID: "Option ID",
  UT_CUL_BAS_AREA: "Area of Basins Discharging in Culvert",
  UT_CUL_BAS: "Basins Discharging in Culvert",
  UT_CUL_LEN: "Culvert Length",
  UT_CUL_Q: "Culvert Volumetric Flow Rate",
  UT_CUL_SIZ: "Culvert Section Size",
  UT_CUL_ID: "Culvert ID",
  UT_CUL_J: "Culvert J",
  UT_MTR_TY: "Meter Type",
  UT_MTR_ID: "Meter ID",
  UT_NOD_TY: "Node Type",
  UT_NOD_ID: "Node ID",
  UT_NOD_ELEV: "Node Elevation",
  UT_ZN: "Utilities Zone",
  UT_ZN_ID: "Utilities Zone ID",
  UT_BLD_TY: "Infrastructure Building Type",
  UT_BLD_ID: "Infrastructure Building ID",
  UT_ELC_AP: "Electric Aparant Power",
  UT_GAL_NAME: "Gallery Name",
  UT_GAL_PEL: "Gallery Project Elevation",
  UT_GAL_KM: "Gallery KM",
  fid: "Unique ID by Feature Layer",
  area: "Shape Area",
  SHAPE__Area: "Shape Area",
  SHAPE__Length: "Shape Length",
  FAR_AVG: "Average Floor Area Ratio",
  FAR_NET: "Net Floor Area Ratio",
  FAR_GRO: "Gross Floor Area Ratio",
  Count: "Count",
};

// Attributes filtered out of analysis
export const EXCLUDED_ATTRIBUTES = [
  "objectid",
  "shape__length",
  "shape__area",
  "uniqueid",
  "xw_id",
  "tc_code",
  "prj_name",
  "mp_ver",
  "mp_rev",
  "mp_sta",
  "des_status",
  "cre_date",
  "app_date",
  "mp_lead",
  "mp_sub",
  "cre_by",
  "mp_rep_li",
  "blo_id",
  "fid",
];

// Attributes accessible to public users
export const PUBLIC_USER_ATTRIBUTES = [
  "blo_id",
  "dis_id",
  "dis_name",
  "land_area",
  "sbl_id",
  "un_id",
  "zn_id",
];

// Available Basemaps
export const BASEMAP_IDS = [
  "satellite",
  "hybrid",
  "streets",
  "topo",
  "gray",
  "dark-gray",
  "oceans",
  "terrain",
  "osm",
  "dark-gray-vector",
  "gray-vector",
  "streets-vector",
  "streets-night-vector",
  "streets-relief-vector",
  "streets-navigation-vector",
  "topo-vector",
];

// Static Images
export const HOME_MASTERPLAN =
  "https://d1kodva67b91g8.cloudfront.net/Home/masterplan.jpg";
export const HOME_GUIDELINES =
  "https://d1kodva67b91g8.cloudfront.net/Home/guidelines.jpg";
export const HOME_VISUALISATION =
  "https://d1kodva67b91g8.cloudfront.net/Home/visualisation.jpg";
export const HOME_EXPERIENCE =
  "https://d1kodva67b91g8.cloudfront.net/Home/experience.jpg";
export const EXPERIENCE_SILK_CITY =
  "https://d1kodva67b91g8.cloudfront.net/Experience/SilkCity.jpg";
export const EXPERIENCE_TWIN_TOWERS =
  "https://d1kodva67b91g8.cloudfront.net/Experience/TwinTowers.jpg";
export const EXPERIENCE_ADMIN_ZONE =
  "https://d1kodva67b91g8.cloudfront.net/Experience/AdminZone.jpg";
