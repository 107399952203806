import React, {
  useState,
  createContext,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import {
  D1_CMP_FEATURE_LAYER_URL,
  D1_DMP_6100HA_URL,
  D1_DMP_FEATURE_LAYER_URL,
  DEFAULT_STAGE,
  LAYER_BLOCK_PLAN,
  PRE_CMP_FEATURE_LAYER_URL,
} from "../../constants";

interface Filter {
  values?: string[] | null;
  min?: number;
  max?: number;
}

interface MasterplanLayerContextType {
  /** All available layers */
  allLayers: any[];
  /** Currently active layers */
  layers: any[];
  /** Cache for layers data based on stages */
  layerCache: { [stage: string]: any };
  /** Current stage of the layers */
  layerStage: string;
  /** Styles for layers categorized by stage and layer name */
  layerStyle: {
    [stage: string]: {
      [layerName: string]: {
        style: string | null;
        type: "discrete" | "continuous";
      };
    };
  };
  /** Filters applied to layers categorized by stage and layer name */
  layerFilters: { [stage: string]: { [layerName: string]: Record<string, Filter> } };
  /** Adds a new layer */
  addLayer: (newLayer: string) => void;
  /** Removes an existing layer */
  removeLayer: (layerToRemove: string) => void;
  /** Initializes all and active layers */
  initialiseLayers: (allLayers: any[], layers?: any[]) => void;
  /** Sets the currently active layers */
  setLayers: (newLayers: string[]) => void;
  /** Sets all available layers */
  setAllLayers: (newLayers: string[]) => void;
  /** Caches layer data for a specific layer ID */
  cacheLayers: (layerId: string, data: any) => void;
  /** Sets the current stage for the layers */
  setLayerStage: (stage: string) => void;
  /** Sets the style for a specific layer */
  setLayerStyle: (
    layerName: string,
    newStyle: string | null,
    styleType: "discrete" | "continuous"
  ) => void;
  /** Sets filters for a specific layer and optionally an attribute */
  setLayerFilters: (layerId: string, data: any, attributeName?: string) => void;
  /** Gets the URL for the current stage */
  getStageURL: () => string;
}

const MasterplanLayerContext = createContext<MasterplanLayerContextType | null>(null);

/**
 * Provides the context for managing masterplan layers, including their state, styles, and filters.
 * 
 * @returns {MasterplanLayerContextType} The masterplan layer context.
 */
export const useLayerContext = () => {
  const context = useContext(MasterplanLayerContext);
  if (!context) {
    throw new Error("useLayer must be used within a LayerProvider");
  }
  return context;
};

interface MasterplanLayerProviderProps {
  /** The children components to be wrapped by the provider */
  children: ReactNode;
}

/**
 * Provides the masterplan layer context to its children.
 * 
 * @param {MasterplanLayerProviderProps} props - The properties for the provider.
 * @param {ReactNode} props.children - The children components.
 * 
 * @returns {JSX.Element} The rendered MasterplanLayerProvider component.
 */
export const MasterplanLayerProvider: React.FC<MasterplanLayerProviderProps> = ({ children }) => {
  const [allLayers, setAllLayersState] = useState<any[]>([]);
  const [layers, setLayerState] = useState<any[]>([]);
  const [layerCache, setLayerCache] = useState<{ [stage: string]: any }>({});
  const [layerStage, setLayerStageState] = useState<string>(DEFAULT_STAGE);
  const [layerStyle, setLayerStyleState] = useState<{
    [stage: string]: {
      [layerName: string]: {
        style: string | null;
        type: "discrete" | "continuous";
      };
    };
  }>({
    "d1-dmp": {
      [LAYER_BLOCK_PLAN]: {
        style: "TC_TRANSECT",
        type: "discrete",
      },
    },
  });
  const [layerFilters, setLayerFilterState] = useState<{
    [stage: string]: { [layerName: string]: Record<string, Filter> };
  }>({});

  const addLayer = useCallback((layer: any) => {
    setLayerState((prevLayers) => {
      if (!prevLayers.some((l) => l.name === layer.name)) {
        return [...prevLayers, layer];
      }
      return prevLayers;
    });
  }, []);

  const removeLayer = useCallback((layer: any) => {
    setLayerState((prevLayers) =>
      prevLayers.filter((l) => l.name !== layer.name)
    );
  }, []);

  const initialiseLayers = useCallback((allLayers: any[], layers?: any[]) => {
    setAllLayersState(allLayers);
    setLayerState(layers ?? allLayers);
  }, []);

  const setLayers = useCallback((newLayers: any[]) => {
    setLayerState(newLayers);
  }, []);

  const setAllLayers = useCallback((newLayers: any[]) => {
    setAllLayersState(newLayers);
  }, []);

  const cacheLayers = useCallback((layerName: string, layerData: any) => {
    setLayerCache((prevCache) => ({ ...prevCache, [layerName]: layerData }));
  }, []);

  const setLayerStage = useCallback((newStage: string) => {
    setLayerStageState(newStage);
  }, []);

  const setLayerStyle = useCallback(
    (
      layerName: string,
      newStyle: string | null,
      styleType: "discrete" | "continuous" = "discrete"
    ) => {
      setLayerStyleState((prevStyle) => {
        const newStyleState = { ...prevStyle };

        if (!newStyleState[layerStage]) {
          newStyleState[layerStage] = {};
        }

        newStyleState[layerStage][layerName] = {
          style: newStyle,
          type: styleType,
        };

        return newStyleState;
      });
    },
    [layerStage]
  );

  const setLayerFilters = useCallback(
    (layerID: string, filter: Filter | any, attributeName?: string) => {
      setLayerFilterState((prevFilters) => {
        // Clone the previous state to avoid direct mutation
        const newFilters = { ...prevFilters };
  
        // Ensure the stage exists in the filters
        if (!newFilters[layerStage]) {
          newFilters[layerStage] = {};
        }
  
        // Handle setting the filter for the layer, with or without an attribute name
        if (attributeName) {
          // Ensure the layer exists for the stage
          if (!newFilters[layerStage][layerID]) {
            newFilters[layerStage][layerID] = {};
          }
  
          // Set the attribute filter
          newFilters[layerStage][layerID][attributeName] = filter;
        } else {
          // Set or replace the layer's filter without targeting a specific attribute
          newFilters[layerStage][layerID] = filter;
        }
        return newFilters;
      });
    },
    [layerStage]
  );

  const getStageURL = useCallback(() => {
    switch (layerStage) {
      case "pre-cmp":
        return PRE_CMP_FEATURE_LAYER_URL;
      case "d1-cmp":
        return D1_CMP_FEATURE_LAYER_URL;
      case "d1-dmp":
        return D1_DMP_FEATURE_LAYER_URL;
      case "d1-dmp-6100ha":
        return D1_DMP_6100HA_URL;
      default:
        console.log(`Unknown layer stage: ${layerStage}`);
        return "";
    }
  }, [layerStage]);

  return (
    <MasterplanLayerContext.Provider
      value={{
        allLayers,
        layers,
        layerCache,
        layerFilters,
        layerStage,
        layerStyle,
        addLayer,
        removeLayer,
        initialiseLayers,
        setLayers,
        setAllLayers,
        cacheLayers,
        setLayerFilters,
        setLayerStage,
        setLayerStyle,
        getStageURL,
      }}
    >
      {children}
    </MasterplanLayerContext.Provider>
  );
};
