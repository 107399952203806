import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuthStatus from "../../hooks/FirebaseAuth";
import Spinner from "../../components/Spinner";

interface PrivateRouteProps {
  /** Whether the route is restricted to users with the 'private' role */
  privateOnly?: boolean;
}

/**
 * A component that guards a route, allowing only authenticated users to access it.
 * If the route is marked as private only, it further restricts access to users with the 'private' role.
 * 
 * @param {PrivateRouteProps} props - The properties for the private route.
 * @param {boolean} [props.privateOnly] - Whether the route is restricted to users with the 'private' role.
 * 
 * @returns {JSX.Element} The rendered component based on authentication status.
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ privateOnly }) => {
  const { userRole, checkingStatus } = useAuthStatus();

  if (checkingStatus) {
    return <Spinner />;
  }

  if (!userRole) {
    return <Navigate to="/" />;
  }

  if (privateOnly && userRole !== "private") {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
