import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { FOOTER_HEIGHT, HEADER_HEIGHT } from "../../constants";
import Spinner from "../Spinner";

/**
 * A component that serves as a fallback UI, displaying a header, a footer, and a spinner.
 * It is typically used to indicate a loading state or to display while content is being fetched.
 *
 * @returns {JSX.Element} The rendered Fallback component.
 */
const Fallback: React.FC = () => {
  return (
    <>
      <Header minimalHeader={true} />
      <div
        style={{
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url(https://d1kodva67b91g8.cloudfront.net/Other/background.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
        }}
      >
        <Spinner />
      </div>
      <Footer />
    </>
  );
};

export default Fallback;
