import React from "react";
import { FOOTER_HEIGHT } from "../../constants";
import XW from "../../images/XW - White on Black.png";

/**
 * A component that renders the footer of the application.
 * The footer includes a copyright notice and a link to the Cross Works's website.
 * 
 * @returns {JSX.Element} The rendered Footer component.
 */
const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: FOOTER_HEIGHT,
          backgroundColor: "#171717",
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          bottom: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 30,
            marginLeft: 30,
            height: "inherit",
            fontSize: 12,
            color: "white",
          }}
        >
          <div>
            ©{currentYear} Yangi Toshkent Digital Twin. Built by{" "}
            <a
              href="https://www.cross-works.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cross Works
            </a>
          </div>
        </div>
        <div style={{ 
          display: "flex", 
          alignItems: "center", 
          gap: 30,
          marginRight: 30, 
          height: 'inherit', 
          fontSize: 18, 
          color: "white", 
        }}>
          <img
            src={XW}
            alt="New Tashkent Logo"
            width={80}
            height={30}
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://www.cross-works.co.uk/")}
          />
        </div>
      </div>
    </>
  );
};

export default Footer;
