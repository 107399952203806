import Query from "@arcgis/core/rest/support/Query";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import esriRequest from "@arcgis/core/request";
import { toTitleCase } from "../../../utils";
import { ATTRIBUTE_KEY_MAPPING, PUBLIC_USER_ATTRIBUTES } from "../../../constants";

/**
 * Recursively fetches all features from an ArcGIS layer.
 *
 * @param {string} layerUrl - The URL of the layer to fetch features from.
 * @param {number} [start=0] - The starting index for fetching features.
 * @param {Array} [features=[]] - Accumulator for features.
 * @returns {Promise<Array>} - A promise that resolves to an array of all fetched features.
 */
export const fetchLayerFeatures: any = async (
  layerUrl: string,
  start = 0,
  features = []
) => {
  const response = await esriRequest(
    `${layerUrl}/query?where=1%3D1&outFields=*&resultOffset=${start}&resultRecordCount=1000&f=json`
  );
  const newFeatures = response.data.features;
  const allFeatures = features.concat(newFeatures);

  if (newFeatures.length === 1000) {
    // There might be more features to fetch
    return fetchLayerFeatures(layerUrl, start + 1000, allFeatures);
  } else {
    return allFeatures;
  }
};

/**
 * Fetches all layers and their features from a given feature service URL.
 *
 * @param {string | undefined} featureLayerUrl - The URL of the feature service.
 * @returns {Promise<Array>} - A promise that resolves to an array of layers with their features.
 */
export const fetchFeatureLayers = async (
  featureLayerUrl: string | undefined
) => {
  try {
    if (!featureLayerUrl) {
      return [];
    }

    const response = await esriRequest(`${featureLayerUrl}?f=json`);
    const fetchedLayers = response.data.layers;

    // Now fetch features for each layer with pagination
    const layersWithFeatures = await Promise.all(
      fetchedLayers.map(async (layer: any) => {
        const layerFeatures = await fetchLayerFeatures(
          `${featureLayerUrl}/${layer.id}`
        );
        return {
          ...layer,
          features: layerFeatures,
          url: featureLayerUrl,
        };
      })
    );

    return layersWithFeatures;
  } catch (error) {
    console.error("Error fetching layers:", error);
  }
};

/**
 * Formats attributes into readable html format.
 *
 * @param {Object} attributes - The attributes to format.
 * @returns {string} - The formatted HTML content.
 */
export const formatAttributesToHTML = (attributes: any) => {
  let content = '<div style="margin: 10px;">';
  const linkAttributes = [
    "Master Plan Reports Link",
    "Road Section Link JPEG",
    "Road Section Link CAD",
  ];

  for (const key in attributes) {

    if (attributes.hasOwnProperty(key)) {
      let value = attributes[key];
      if (value === null || value === undefined) {
        value = "N/A";
      }

      

      // Check if the current attribute key is one of the link attributes
      if (linkAttributes.includes(key)) {
        // create a hyperlink with "Click Here" text
        if (key === "Master Plan Reports Link") {
          // change its value
          value =
            "https://crossworks916.sharepoint.com/:f:/s/newtashkent/EkRaiLJCER5DmMQKkrn5pCoBmBpzEvVYqn6ajbQQABRFIw?e=lt7aNm";
        }
        content += `<div><strong>${key}:</strong> <a href="${value}" target="_blank">Click Here</a></div>`;
      } else {
        // If not a link attribute, display the attribute value as usual
        content += `<div><strong>${key}:</strong> ${value}</div>`;
      }
    }
  }
  content += "</div>";
  return content;
};

/**
 * Converts ArcGIS geometry to GeoJSON format.
 *
 * @param {string} type - The type of the geometry (e.g., "point", "polyline", "polygon").
 * @param {Object} geometry - The ArcGIS geometry object.
 * @returns {Object | null} - The converted GeoJSON geometry object or null if unsupported type.
 */
export const convertArcGISGeometryToGeoJSON = (type: string, geometry: any) => {
  if (!geometry) return null;

  switch (type) {
    case "point":
      return {
        type: "Point",
        coordinates: [geometry.x, geometry.y],
      };

    case "polyline":
      return {
        type: "LineString",
        coordinates: geometry.paths[0].map((coord: any) => [
          coord[0],
          coord[1],
        ]),
      };

    case "polygon":
      return {
        type: "Polygon",
        coordinates: [
          geometry.rings[0].map((coord: any) => [coord[0], coord[1]]),
        ],
      };

    default:
      console.warn(`Unsupported geometry type: ${type}`);
      return null;
  }
};

/**
 * Fetches all features from a given layer using a query.
 *
 * @param {FeatureLayer} layer - The feature layer to query.
 * @param {Query} query - The query to use for fetching features.
 * @returns {Promise<Array>} - A promise that resolves to an array of all fetched features.
 */
export const fetchAllFeatures = async (layer: FeatureLayer, query: Query) => {
  let allFeatures = [];
  query.start = 0;
  query.num = 2000; // Set to maxRecordCount of the server

  let queryResult = await layer.queryFeatures(query);
  allFeatures.push(...queryResult.features);

  while (queryResult.exceededTransferLimit) {
    query.start += query.num;
    queryResult = await layer.queryFeatures(query);
    allFeatures.push(...queryResult.features);
  }

  return allFeatures;
};

/**
 * Downloads layer data to the device as GeoJSON.
 *
 * @param {Array} layers - The layers to download.
 * @param {string} userRole - The role of the user, defaults to "public".
 */
export const downloadLayers = async (layers: any[], userRole: string | null = "public") => {
  // Return if empty
  if (layers.length === 0) return;

  try {
    let features = [];

    for (const layer of layers) {
      // Query the layer for its features
      const query = layer.createQuery();
      query.outFields = ["*"];
      query.where = layer.definitionExpression || "1=1";

      const allLayerFeatures = await fetchAllFeatures(layer, query);
      features.push(
        ...allLayerFeatures.map((f) => {
          const feature = f.toJSON();
          const geometry = convertArcGISGeometryToGeoJSON(
            layer.geometryType,
            feature.geometry
          );

          // Filter attributes based on userRole
          let properties = feature.attributes;
          if (userRole !== "private") {
            properties = Object.keys(properties)
              .filter((key) =>
                PUBLIC_USER_ATTRIBUTES.some(
                  (attr) => attr.toLowerCase() === key.toLowerCase()
                )
              )
              .reduce((obj: any, key: string) => {
                obj[key] = properties[key];
                return obj;
              }, {});
          }

          return {
            type: "Feature",
            properties: properties, // Ensure properties are not null
            geometry: geometry,
          };
        })
      );
    }

    // Ensure features array does not contain invalid entries
    features = features.filter(
      (feature) => feature.geometry && feature.properties
    );

    let geojson = {
      type: "FeatureCollection",
      features: features,
    };

    const blob = new Blob([JSON.stringify(geojson)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const link = document.createElement("a");
    link.download = "tnc-geo-data.geojson";
    link.href = url;
    link.click();

    // Clean up by revoking the object URL
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting geojson:", error);
  }
};

/**
 * Creates a unique value renderer for an ArcGIS layer based on a specific property and set of colors.
 *
 * @param {string} field - The field to create unique values for.
 * @param {Array<string>} colors - The array of colors to use for the unique values.
 * @param {Array} uniqueValues - The array of unique values.
 * @param {string} geometryType - The geometry type of the layer ("polygon" or "polyline").
 * @returns {UniqueValueRenderer} - The created unique value renderer.
 */
export const createUniqueValueRenderer = (
  field: string,
  colors: string[],
  uniqueValues: any[],
  geometryType: string = "esriGeometryPolygon"
) => {
  const uniqueValueInfos = uniqueValues.map((value, index) => {
    // Cycle through colors if more values than colors
    const color = colors[index % colors.length];
    let symbol;
    switch (geometryType) {
      case "esriGeometryPolygon":
        symbol = { type: "simple-fill", color: color };
        break;
      case "esriGeometryPolyline":
        symbol = { type: "simple-line", color: color, width: "2px" };
        break;
      case "esriGeometryPoint":
        symbol = { type: "simple-marker", color: color, size: "8px" };
        break;
      default:
        throw new Error(`Unsupported geometry type: ${geometryType}`);
    }

    return {
      value: value,
      symbol: symbol,
    };
  });

  return new UniqueValueRenderer({
    field: field,
    uniqueValueInfos: uniqueValueInfos,
  });
};

interface Filter {
  values?: string[] | null;
  min?: number;
  max?: number;
}

/**
 * Constructs a definition expression for a layer to filter certain attributes.
 *
 * @param {Record<string, Filter>} filters - The filters to apply. Each filter can be an array of values or an object with min and max properties for range filtering.
 * @returns {string} - The constructed definition expression.
 */
export const constructDefinitionExpression = (
  filters: Record<string, Filter>
): string => {
  const expressions = Object.entries(filters).flatMap(
    ([attribute, filter]): string[] => {
      const { values, min, max } = filter;

      const rangeExpressions: string[] = [];
      if (min !== undefined && max !== undefined) {
        rangeExpressions.push(`${attribute} BETWEEN ${min} AND ${max}`);
      } else if (min !== undefined) {
        rangeExpressions.push(`${attribute} >= ${min}`);
      } else if (max !== undefined) {
        rangeExpressions.push(`${attribute} <= ${max}`);
      }

      const valueExpressions: string[] = [];
      if (values !== null && values !== undefined) {
        if (values.length === 0) {
          valueExpressions.push("1=0"); // If array is empty, show nothing
        } else {
          const stringifiedValues = values.map((v) => `'${v}'`).join(", ");
          valueExpressions.push(`${attribute} IN (${stringifiedValues})`);
        }
      }

      return [...rangeExpressions, ...valueExpressions];
    }
  );

  return expressions.length > 0 ? expressions.join(" AND ") : "1=1";
};

/**
 * Fetches all unique values of a layer attribute directly from the feature service.
 *
 * @param {string} url - The URL of the feature service.
 * @param {string} layerName - The name of the layer.
 * @param {string} attributeName - The name of the attribute to fetch unique values for.
 * @returns {Promise<Array>} - A promise that resolves to an array of unique attribute values.
 */
export const getAllUniqueAttributeValues = async (
  url: string,
  layerName: string,
  attributeName: string
) => {
  try {
    // Construct the URL for the specific layer within the feature service
    const layerURL = `${url}/${layerName}/query`;

    // Construct the query parameters
    const queryParams = {
      where: "1=1",
      outFields: [attributeName],
      returnDistinctValues: true,
      returnGeometry: false,
      f: "json",
    };

    // Make the request to the layer query endpoint
    const response = await esriRequest(layerURL, {
      query: queryParams,
      responseType: "json",
    });

    // Extract unique values from the response, ignoring case for attribute names
    const uniqueValues = new Set();
    response.data.features.forEach((feature: any) => {
      // Find the correct attribute by ignoring case
      const attributeKey = Object.keys(feature.attributes).find(
        (key) => key.toLowerCase() === attributeName.toLowerCase()
      );
      if (attributeKey) {
        const value = feature.attributes[attributeKey];
        uniqueValues.add(value);
      }
    });

    return Array.from(uniqueValues);
  } catch (error) {
    console.error("Error fetching unique attribute values:", error);
    return [];
  }
};



/** Helper function to get human-readable names of attribute */
export const getReadableName = (key: string) => {
  return ATTRIBUTE_KEY_MAPPING[key] || toTitleCase(key);
};

/**
 * Transforms array of attribute objects to more user-friendly names based on the attributeKeyMapping.
 * This function is case-insensitive.
 *
 * @param {Object} attributes - The attributes to transform.
 * @returns {Object} - The transformed attributes.
 */
export const transformAttributes = (attributes: { [key: string]: any }) => {
  const transformed: { [key: string]: any } = {};
  const lowerCaseMapping = Object.keys(ATTRIBUTE_KEY_MAPPING).reduce((acc: any, key: string) => {
    acc[key.toLowerCase()] = ATTRIBUTE_KEY_MAPPING[key];
    return acc;
  }, {});

  for (const key in attributes) {
    const lowerCaseKey = key.toLowerCase();
    const newKey = lowerCaseMapping[lowerCaseKey] || key;
    let value = attributes[key];

    // Check if the value is a float and format it to two decimal places
    if (typeof value === "number" && !Number.isInteger(value)) {
      value = value.toFixed(2);
    }

    transformed[newKey] = value;
  }
  return transformed;
};

/**
 * Transforms an array of attribute keys to their more user-friendly names based on the attributeKeyMapping.
 * This function is case-insensitive.
 *
 * @param {string[]} attributes - The attribute keys to transform.
 * @returns {string[]} - The transformed attribute keys.
 */
export const transformAttributeKeys = (attributes: string[]) => {
  const lowerCaseMapping = Object.keys(ATTRIBUTE_KEY_MAPPING).reduce((acc: any, key: string) => {
    acc[key.toLowerCase()] = ATTRIBUTE_KEY_MAPPING[key];
    return acc;
  }, {});

  return attributes.map(key => lowerCaseMapping[key.toLowerCase()] || key);
};



/**
 * Creates HTML content for a popup based on the array of attributes.
 *
 * @param {Object} attributes - The attributes to display in the popup.
 * @returns {string} - The HTML content for the popup.
 */
export const createPopupContent = (attributes: {[key: string]: any}, userRole: string | null = "public"): string => {

  const transformedAttributes = transformAttributes(attributes);
  const publicUserPopupAttributes = transformAttributeKeys(PUBLIC_USER_ATTRIBUTES)

  // Attributes that require hyperlink
  const linkAttributes = [
    "Master Plan Reports Link",
    "Road Section Link JPEG",
    "Road Section Link CAD",
  ];

  // Attributes that are to be excluded from popup
  const excludedAttributes = [
    "Unique ID by Database",
    "Unique ID by Feature Layer",
    "Project Name",
    "Master Plan Version",
    "Master Plan Revision",
    "Design Status",
    "Creation date",
    "Approval Date",
    "Design Lead",
    "Sub Consultant",
    "Created By",
    "Master Plan Reports Link",
    "Master Plan Version Stage",
    "Shape Area",
    "Shape Length"
  ];

   const content = Object.entries(transformedAttributes)
    .filter(([key, value]) => {
      if (excludedAttributes.includes(key)) {
        return false;
      }
      if (userRole !== "private" && !publicUserPopupAttributes.includes(key)) {
        return false;
      }
      return true;
    })
    .map(([key, value]) => {
      let formattedValue = value;
      if (linkAttributes.includes(key)) {
        formattedValue = `<a href="${value}" target="_blank">Click Here</a>`;
      }
      return `<div><strong>${key}:</strong> ${formattedValue}</div>`;
    })
    .join("");

  return content;
};
