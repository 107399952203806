import React, { useEffect } from "react";
import { DESKTOP_MEDIUM_BREAKPOINT } from "../../constants";
import { debounce } from "lodash";

// Create context with default values
const MobileContext = React.createContext(false);

interface MobileDesignProps {
  /** The children components to be wrapped by the provider */
  children: React.ReactNode;
  /** Optional prop to prevent mobile design */
  preventMobile?: boolean;
}

/**
 * A provider component that wraps your app and provides mobile state.
 * 
 * @param {MobileDesignProps} props - The properties for the provider.
 * @param {React.ReactNode} props.children - The children components.
 * @param {boolean} [props.preventMobile] - Optional prop to prevent mobile design.
 * 
 * @returns {JSX.Element} The rendered MobileDesign provider component.
 */
const MobileDesign: React.FC<MobileDesignProps> = ({ children }) => {
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth < DESKTOP_MEDIUM_BREAKPOINT
  );

  // Handle window resize events
  useEffect(() => {
    const handleResize = debounce(() => {
      const mobile = window.innerWidth < DESKTOP_MEDIUM_BREAKPOINT;
      if (isMobile !== mobile) setIsMobile(mobile);
    }, 100);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>
  );
};

export { MobileContext, MobileDesign };
