import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from "../../firebase";

/**
 * Custom hook to check the authentication status and user role.
 * It listens to the authentication state changes and fetches user data from Firestore.
 * 
 * @returns {Object} The authentication status, user role, and pixel streaming status.
 * @returns {string | null} userRole - The role of the authenticated user.
 * @returns {boolean | null} userPixelStreaming - The pixel streaming status of the authenticated user.
 * @returns {boolean} checkingStatus - Whether the authentication status is being checked.
 */
const useAuthStatus = () => {
    const [userRole, setUserRole] = useState<string | null>(null);
    const [userPixelStreaming, setUserPixelStreaming] = useState<boolean | null>(null);
    const [checkingStatus, setCheckingStatus] = useState<boolean>(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userDocRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    setUserRole(userDoc.data().role);
                    setUserPixelStreaming(userDoc.data().pixel_streaming);
                } else {
                    setUserRole("public");
                    setUserPixelStreaming(false);
                }
            } else {
                setUserRole(null);
                setUserPixelStreaming(null);
            }
            setCheckingStatus(false);
        });

        return () => unsubscribe(); // Clean up the subscription
    }, []);

    return { userRole, userPixelStreaming, checkingStatus };
}

export default useAuthStatus;
