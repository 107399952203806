// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import "firebase/storage";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAnalytics, setAnalyticsCollectionEnabled  } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDMDG4qkk4V4XNdVZssVMc0vXHdXvG646s",
  authDomain: "tncdt-930db.firebaseapp.com",
  projectId: "tncdt-930db",
  storageBucket: "tncdt-930db.appspot.com",
  messagingSenderId: "256465980654",
  appId: "1:256465980654:web:71ad3152c62933a612b7ff",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);

// Disable advertising features for Firebase Analytics
setAnalyticsCollectionEnabled(analytics, true);