import React, {
  useState,
  createContext,
  useContext,
  ReactNode,
  useCallback,
} from "react";

interface VisualisationLayerContextType {
  /** Cache for visualisation layers data */
  layerCache: any[];
  /** Caches layers data */
  cacheLayers: (data: any[]) => void;
}

const VisualisationLayerContext =
  createContext<VisualisationLayerContextType | null>(null);

/**
 * Hook to use the VisualisationLayerContext.
 * 
 * @returns {VisualisationLayerContextType} The visualisation layer context.
 * @throws Will throw an error if used outside of a VisualisationLayerProvider.
 */
export const useVisualisationLayerContext = () => {
  const context = useContext(VisualisationLayerContext);
  if (!context) {
    throw new Error("useLayer must be used within a LayerProvider");
  }
  return context;
};

interface VisualisationLayerProviderProps {
  /** The children components to be wrapped by the provider */
  children: ReactNode;
}

/**
 * Provides the visualisation layer context to its children.
 * 
 * @param {VisualisationLayerProviderProps} props - The properties for the provider.
 * @param {ReactNode} props.children - The children components.
 * 
 * @returns {JSX.Element} The rendered VisualisationLayerProvider component.
 */
export const VisualisationLayerProvider: React.FC<
  VisualisationLayerProviderProps
> = ({ children }) => {
  const [layerCache, setLayerCache] = useState<any[]>([]);

  const cacheLayers = useCallback((layerData: any[]) => {
    setLayerCache(layerData);
  }, []);

  return (
    <VisualisationLayerContext.Provider
      value={{
        layerCache,
        cacheLayers,
      }}
    >
      {children}
    </VisualisationLayerContext.Provider>
  );
};
